<template>
  <div class="flex flex-col lg:flex-row items-center gap-2">
    <InputField :placeholder="t('brand')" v-model="newMarque"/>
    <InputField :placeholder="t('model')" v-model="newModel"/>
    <InputField :placeholder="t('consommation')" v-model="newConsommation" type="number"/>
    <InputField :placeholder="t('price')" v-model="newPrix" type="number"/>
    <InputField :placeholder="t('urlImgPrinter')" v-model="newUrlImage" />

    <BlueButton :text="t('addAction')" @click="createImprimante" class="w-full lg:w-auto" />
  </div>
</template>

<script setup>

import {ref} from "vue";
import InputField from "@/components/molecules/InputField.vue";
import BlueButton from "@/components/molecules/BlueButton.vue";
import {t} from "@/utils/Traduction";
import {NotificationStores} from "@/stores/NotificationStores";
import {ImprimantesStore} from "@/stores/ImprimanteStore";

const notificationStore = NotificationStores();
const imprimanteStore = ImprimantesStore();

const newMarque = ref("");
const newModel = ref("");
const newConsommation = ref(0);
const newPrix = ref(0);
const newUrlImage = ref("");

const createImprimante = () => {

  if(newMarque.value === "" || newModel.value === "" || newConsommation.value === 0 || newPrix.value === 0 || newUrlImage.value === "") {
    notificationStore.addNotification(t('needFillAllFields'), 'error');
    return;
  }

  imprimanteStore.createImprimante({
    marque: newMarque.value,
    modele: newModel.value,
    consommation: newConsommation.value,
    prix: newPrix.value,
    urlImage: newUrlImage.value
  }, () => {
    newMarque.value = "";
    newModel.value = "";
    newConsommation.value = 0;
    newPrix.value = 0;
    newUrlImage.value = "";
  });
}
</script>

<style scoped>

</style>