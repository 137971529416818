<template>
  <div class="flex flex-row justify-center mt-4">
    <BlueClickableText @click="createCouleur" :text='t("addColor")'/>
  </div>

  <div class="flex flex-wrap justify-center">
    <IdCardCouleur v-for="couleur in couleurStore.couleurs" :couleur="couleur" :key="couleur.uuid" />
  </div>

  <ModalCreationCouleurComponent :isVisible="couleurStore.showModalCreation"/>
</template>

<script setup>

import {CouleurStore} from "@/stores/CouleurStore";
import IdCardCouleur from "@/components/organismes/idCardCouleur.vue";
import {enumeration} from "../../Enumeration";
import {router} from "@/routeur";
import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {NotificationStores} from "@/stores/NotificationStores";
import ModalCreationCouleurComponent from "@/components/organismes/ModalCreationCouleurComponent.vue";
import {t} from "@/utils/Traduction";
import BlueClickableText from "@/components/molecules/BlueClickableText.vue";

const couleurStore = CouleurStore();
const utilisateurStore = UtilisateurStores();
const notificationStores = NotificationStores();

if(utilisateurStore.role !== enumeration.role.ADMIN) {
  utilisateurStore.logout(false);
  notificationStores.addNotification(t('youNeedToBeAdminToAccessThisPage'), 'error');
  router.push({ path: "/" });
}

couleurStore.loadCouleurs();

const createCouleur = () => {
  couleurStore.showModalCreation = true;
}

</script>

<style scoped>

</style>