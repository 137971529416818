<template>
  <div class="flex flex-col lg:flex-row items-between gap-4 m-4">
    <InputField :placeholder="t('brandName')" v-model="newName" />
    <BlueButton :text="t('addAction')" @click="createMarque" />
  </div>
</template>

<script setup>

import {t} from "@/utils/Traduction";
import BlueButton from "@/components/molecules/BlueButton.vue";
import InputField from "@/components/molecules/InputField.vue";
import {ref} from "vue";
import {NotificationStores} from "@/stores/NotificationStores";
import {MarqueStore} from "@/stores/MarqueStore";

const notificationStores = NotificationStores();
const marqueStore = MarqueStore();

const newName = ref("");

const createMarque = () => {
  if(newName.value === "") {
    notificationStores.addNotification(t("needFillBrandName"), 'error');
    return;
  }

  marqueStore.createMarque(newName.value);
  newName.value = "";
}

</script>

<style scoped>

</style>