<template>
  <BaseModal :isVisible="isVisible">
      <TitreDeux :content="t('createAColor')"/>
      <InputField :placeholder="t('colorName')" v-model="newCouleur"/>

      <div class="flex flex-row justify-end gap-4">
        <BlackClickableText :text="t('cancelAction')" @click="closeModal"/>
        <BlueButton :text="t('validate')" @click="createCouleur"/>
      </div>
  </BaseModal>
</template>

<script setup>

import {CouleurStore} from "@/stores/CouleurStore";
import {defineProps, ref} from "vue";
import xhr from "@/utils/xhr";
import {NotificationStores} from "@/stores/NotificationStores";
import {jsonValidator} from "@/utils/JsonValidator";
import {t} from "@/utils/Traduction";
import TitreDeux from "@/components/atoms/TitreDeux.vue";
import InputField from "@/components/molecules/InputField.vue";
import BlackClickableText from "@/components/molecules/BlackClickableText.vue";
import BlueButton from "@/components/molecules/BlueButton.vue";
import BaseModal from "@/components/molecules/BaseModal.vue";

const couleurStore = CouleurStore();
const notificationStore = NotificationStores();

const newCouleur = ref("");

defineProps({
  isVisible: {
    type: Boolean,
    required: true
  }
});

const createCouleur = async () => {
  if(newCouleur.value === "") {
    notificationStore.addNotification(t('colorNameCannotBeEmpty'), "error");
    return;
  }

  xhr.addRequestToQueue("POST", "/couleurs", {libelle: newCouleur.value}, true, true, false,
    (response) => {
      if (response.code !== 201) {
        if (response.code === 0 || !jsonValidator.validate(response.response)) {
          notificationStore.addNotification(
              t('errorDuringCreateColor'),
              "error");
        } else {
          notificationStore.addNotification(
              JSON.parse(response.response).message,
              "error");
        }
      } else {
        notificationStore.addNotification(`${t("color")} ${newCouleur.value} ${t("createSucces")}`, "success");

        couleurStore.couleurs.push(JSON.parse(response.response));

        newCouleur.value = "";

        closeModal();
      }
    });
}

const closeModal = () => {
  couleurStore.showModalCreation = false;
}

</script>

<style scoped>

</style>