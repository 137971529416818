<template>
  <BaseKeyValue :cles="t('remainingWeight')">
    <SimpleText :content="props.bobine.poidsRestant + 'g'" />
  </BaseKeyValue>

  <BaseKeyValue :cles="t('color')">
    <SimpleText :content="bobine.couleur.libelle" />
  </BaseKeyValue>

  <BaseKeyValue :cles="t('brand')">
    <SimpleText :content="bobine.marque.nom" />
  </BaseKeyValue>

  <BaseKeyValue :cles="t('filaments')">
    <SimpleText :content="bobine.typeFilament.libelle" />
  </BaseKeyValue>
</template>

<script setup>

import {t} from "@/utils/Traduction";
import {defineProps} from "vue";
import BaseKeyValue from "@/components/molecules/BaseKeyValue.vue";
import SimpleText from "@/components/atoms/SimpleText.vue";

const props = defineProps({
  bobine: {
    type: Object,
    required: true
  }
})

</script>

<style scoped>

</style>