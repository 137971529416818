<template>
  <div class="w-full lg:w-auto">
    <LabelInput :class="{'hidden': !showLabel}" :for="placeholder" :label="label" />
    <input
        v-model="value"
        :type="type"
        :id="placeholder"
        :placeholder="placeholder"
        :min="min"
        class="mt-1 px-3 block w-full rounded border border-gray-400"
        :class="{'py-2' : type !== 'color'}"
        :required="required"
        @blur="emit('onChange', value)"
        @keyup.enter="emit('onChange', value)"
    />
  </div>
</template>

<script setup>

import {defineEmits, defineModel, defineProps, ref, watch} from "vue";
import LabelInput from "@/components/atoms/LabelInput.vue";

const props = defineProps({
  type: {
    default: "text",
    type: String,
  },
  placeholder: {
    type: String,
    required: true,
  },
  label: {
    type: String,
  },
  min: {
    type: Number,
    default: 0,
  },
  showLabel: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue", "onChange"]);
const model = defineModel({type: [String, Number, Boolean]});
const value = ref(model.value);

watch(() => model.value, (newValue) => {
  value.value = newValue;
});

watch(value, (newValue) => {
  emit("update:modelValue", newValue);
});

const label = ref(props.label || props.placeholder);

</script>

<style scoped>

</style>