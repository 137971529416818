<template>
  <BaseDeleteModal :text='t("attentionAlertDeleteInformation")' :isVisible="informationStore.showModalDelete"
      @closeModal="closeModalDelete" />

  <CreateInformationForm />

  <div class="flex flex-wrap mx-8">
    <IdCardInformation v-for="information in informationStore.informations" :key="information.uuid" :information="information"
         :show-modal-delete="showModalDelete" />
  </div>

  <TitreUn :content="t('noInformation')" v-if="informationStore.informations.length === 0"
           class="absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%]"/>
</template>

<script setup>

import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {router} from "@/routeur";
import {NotificationStores} from "@/stores/NotificationStores";
import {enumeration} from "../../Enumeration";
import {t} from "@/utils/Traduction";
import {InformationStore} from "@/stores/InformationStore";
import IdCardInformation from "@/components/organismes/IdCardInformation.vue";
import {ref} from "vue";
import TitreUn from "@/components/atoms/TitreUn.vue";
import CreateInformationForm from "@/components/organismes/CreateInformationForm.vue";
import BaseDeleteModal from "@/components/molecules/BaseDeleteModal.vue";

const utilisateurStore = UtilisateurStores();
const notificationStores = NotificationStores();

if(utilisateurStore.role !== enumeration.role.ADMIN) {
  utilisateurStore.logout(false);
  notificationStores.addNotification(t("youNeedToBeAdminToAccessThisPage"), 'error');
  router.push({ path: "/" });
}

const informationStore = InformationStore();

informationStore.loadInformations();

const uuidInformationModale = ref(0);

const closeModalDelete = (supprimer) => {
  if(supprimer) {
    informationStore.deleteInformation(uuidInformationModale.value);
  }

  informationStore.showModalDelete = false;
}

const showModalDelete = (uuid) => {
  uuidInformationModale.value = uuid;
  informationStore.showModalDelete = true;
}
</script>

<style scoped>

</style>