<template>
  <form @submit.prevent="register" class="w-full flex flex-col gap-4">
    <InputField :placeholder='t("email")' type="email" v-model="email" required showLabel/>
    <InputField :placeholder='t("password")' type="password" v-model="password" required showLabel/>
    <InputField :placeholder='t("confirmPassword")' type="password" v-model="confirmPassword" required showLabel/>
    <DropDownField :placeholder='t("chooseARole")' :label='t("role")'
        :values="utilisateurStore.rolesAvailable.map(role => ({id: role.uuid, display: role.libelle}))"
        v-model="selectedRole" required showLabel/>
    <InputField :placeholder='t("delivryAdresse")' v-model="livraison" required showLabel/>
    <DropDownField :placeholder='t("chooseALanguage")' :label='t("language")'
       :values="enumeration.countryAvailable" showLabel
       v-model="langue" required />

    <BlueButton :text="t('registerMe')" type="submit" />
  </form>
</template>

<script setup>

import {t} from "@/utils/Traduction";
import {enumeration} from "../../../Enumeration";
import BlueButton from "@/components/molecules/BlueButton.vue";
import DropDownField from "@/components/molecules/DropDownField.vue";
import InputField from "@/components/molecules/InputField.vue";
import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {ref} from "vue";
import xhr from "@/utils/xhr";
import {jsonValidator} from "@/utils/JsonValidator";
import {NotificationStores} from "@/stores/NotificationStores";


const utilisateurStore = UtilisateurStores();
const notificationStore = NotificationStores();

const email = ref('');
const password = ref('');
const confirmPassword = ref('');
const selectedRole = ref(-1);
const livraison = ref('');
const langue = ref(-1);


const register = async () => {
  if(password.value !== confirmPassword.value) {
    notificationStore.addNotification(t("passwordNotMatch"), 'error');
    return;
  }

  xhr.addRequestToQueue("POST", "/utilisateurs/register",  {
      email: email.value, password: password.value, confirmPassword: confirmPassword.value,
      uuidRole: selectedRole.value, livraison: livraison.value, langue: langue.value}, true, true, false,
    (response) => {
      if (response.code !== 201) {
        if (response.code === 0 || !jsonValidator.validate(response.response)) {
          notificationStore.addNotification(
              t("errorDuringRegisterUser"),
              "error");
        } else {
          notificationStore.addNotification(
              JSON.parse(response.response).message,
              "error");
        }
      } else {
        notificationStore.addNotification(
            t("userRegisterSuccess"),
            "success");

        email.value = '';
        password.value = '';
        confirmPassword.value = '';
        selectedRole.value = -1;
        livraison.value = '';
        langue.value = -1;
      }
    });
}
</script>

<style scoped>

</style>