<template>
  <BaseIdCard :numberPerRow="4" >
    <BaseKeyValue :cles="t('uuid')">
      <SimpleText :content="props.typeFilament.uuid" />
    </BaseKeyValue>

    <BaseKeyValue :cles="t('label')">
      <SimpleText :content="props.typeFilament.libelle" v-show="!isEditing"/>
      <InputField :placeholder="t('label')" v-model="editLibelle" v-show="isEditing" />
    </BaseKeyValue>

    <div class="mt-4 flex flex-row gap-2 justify-end">
      <GreenCheckIcon v-show="isEditing" @click="onEdit" />
      <OrangeEditIcon v-show="!isEditing" @click="() => {isEditing = true;}" />
      <RedTrashIcon @click="onDelete" />
    </div>
  </BaseIdCard>
</template>

<script setup>

import {t} from "@/utils/Traduction";
import {defineProps, ref} from 'vue';
import {NotificationStores} from "@/stores/NotificationStores";
import {TypeFilamentStore} from "@/stores/TypeFilamentStore";
import GreenCheckIcon from "@/components/atoms/GreenCheckIcon.vue";
import OrangeEditIcon from "@/components/atoms/OrangeEditIcon.vue";
import RedTrashIcon from "@/components/atoms/RedTrashIcon.vue";
import BaseIdCard from "@/components/molecules/BaseIdCard.vue";
import BaseKeyValue from "@/components/molecules/BaseKeyValue.vue";
import SimpleText from "@/components/atoms/SimpleText.vue";
import InputField from "@/components/molecules/InputField.vue";

const props = defineProps({
  typeFilament: Object,
  showModalDelete: Function
});

const notificationStores = NotificationStores();
const typeFilamentStore = TypeFilamentStore();

const isEditing = ref(false);
const editLibelle = ref(props.typeFilament.libelle);


const onDelete = () => {
  props.showModalDelete(props.typeFilament.uuid);
}

const onEdit = () => {
  if(editLibelle.value === "") {
    notificationStores.addNotification(t("libelleCannotBeEmpty"), 'error');
    return;
  }

  typeFilamentStore.editTypeFilament(editLibelle.value, props.typeFilament.uuid);
  isEditing.value = false;
}

</script>

<style scoped>

</style>