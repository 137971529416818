<template>
  <div v-if="utilisateurStore.role === enumeration.role.ADMIN" class="w-full md:w-auto px-8 md:px-0 md:mx-32 mt-16">
    <TitreUn :content="t('versionManagement')"/>

    <CreateVersionForm />
  </div>

  <IdCardVersion v-for="version of versionStore.versions" :key="version.uuid"
                 :version="version"/>

  <div class="mb-16"/>

</template>

<script setup>

import {VersionStore} from "@/stores/VersionStore";
import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {enumeration} from "../../Enumeration";
import IdCardVersion from "@/components/organismes/idCardVersion.vue";
import {t} from "@/utils/Traduction";
import TitreUn from "@/components/atoms/TitreUn.vue";
import CreateVersionForm from "@/components/organismes/CreateVersionForm.vue";

const versionStore = new VersionStore();
const utilisateurStore = new UtilisateurStores();

if(versionStore.versions.length === 0) {
  versionStore.loadVersions();
}



</script>

<style scoped>
</style>