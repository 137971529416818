<template>
    <CreationCommandeForm />
</template>

<script setup>

import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {router} from "@/routeur";
import {NotificationStores} from "@/stores/NotificationStores";
import {t} from "@/utils/Traduction";
import CreationCommandeForm from "@/components/organismes/CreationCommandeForm.vue";

const utilisateurStore = UtilisateurStores();
const notificationStore = NotificationStores();

if(!utilisateurStore.isConnected) {
  notificationStore.addNotification(t("youNeedToBeConnectedToAccessThisPage"), 'error');
  router.push({ path: "/"});
}

utilisateurStore.loadClients();

</script>

<style scoped>

</style>