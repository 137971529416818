<template>
  <BaseIdCard :innerClass="'p-6 gap-2'">
    <CroixRougeIcon @click="deleteMessage" class="absolute top-2 right-2"/>
    <TitreUn class="text-center" :content="props.message.prenom + ' ' + props.message.nom" />
    <SimpleText textCenter color="text-gray-600" :content="props.message.email" class="text-center"/>
    <SimpleText :content="t('message')" color="text-gray-700" bolding="font-medium" textCenter/>
    <TextEncadrer :content="props.message.message"/>
  </BaseIdCard>
</template>

<script setup>

import {defineProps} from 'vue';
import xhr from "@/utils/xhr";
import {NotificationStores} from "@/stores/NotificationStores";
import {ContactStores} from "@/stores/ContactStore";
import {jsonValidator} from "@/utils/JsonValidator";
import {t} from "@/utils/Traduction";
import CroixRougeIcon from "@/components/atoms/CroixRougeIcon.vue";
import TitreUn from "@/components/atoms/TitreUn.vue";
import SimpleText from "@/components/atoms/SimpleText.vue";
import TextEncadrer from "@/components/molecules/TextEncadrer.vue";
import BaseIdCard from "@/components/molecules/BaseIdCard.vue";

const notificationStore = NotificationStores();
const contactStore = ContactStores();

const props = defineProps({
  message: Object,
});

const deleteMessage = async () => {
  xhr.addRequestToQueue("DELETE", '/contacts/' + props.message.uuid, undefined, true, true, false,
    (response) => {
      if (response.code !== 200) {
        if (response.code === 0 || !jsonValidator.validate(response.response)) {
          notificationStore.addNotification(
              t("errorDuringDeleteMessage"),
              "error");
        } else {
          notificationStore.addNotification(
              JSON.parse(response.response).message,
              "error");
        }
      } else {
        notificationStore.addNotification(
            t('successDeleteMessage'),
            "success");

        contactStore.removeFromUuid(props.message.uuid);
      }
    });
}

</script>

<style scoped>

</style>