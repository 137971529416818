<template>
  <div class="flex flex-col items-center justify-center">
    <div :class="$attrs.class">
      <SimpleText :content="titre" />

      <div>
        <canvas ref="canvas" width="100%" height="75%"></canvas>
      </div>
    </div>
  </div>
</template>

<script setup>

import {defineProps, onMounted, ref, useTemplateRef, watch} from 'vue'
import SimpleText from "@/components/atoms/SimpleText.vue";
import {Chart} from "chart.js";
import {t} from "@/utils/Traduction";

const props = defineProps({
  titre: {
    type: String,
    required: true
  },
  stats: {
    type: Object,
    required: true
  }
});

const canvasRef = useTemplateRef("canvas");
const bar = ref(null);

const chartData = (dict) => {
  const labels = [t("january"), t("february"), t("march"), t("april"), t("may"), t("june"),
    t("july"), t("august"), t("september"), t("october"), t("november"), t("december")];

  const currentYear = new Date().getFullYear();
  const yearsLabels = [currentYear - 2, currentYear - 1, currentYear];

  const datasets = [
    {label: yearsLabels[0], data: [], backgroundColor: 'rgba(255, 99, 132, 0.5)'},
    {label: yearsLabels[1], data: [], backgroundColor: 'rgba(54, 162, 235, 0.5)'},
    {label: yearsLabels[2], data: [], backgroundColor: 'rgba(255, 206, 86, 0.5)'},
  ];

  labels.forEach((label, index) => {
    const month = index + 1;
    datasets.forEach(dataset => {
      const year = dataset.label;
      const monthKey = `${month < 10 ? '0' + month : month}-${year}`;
      const value = dict[monthKey] || 0;
      dataset.data.push(value > 0 ? value: 0.1);
    });
  });
  return {labels, datasets};
}

onMounted(() => {
  watch([() => props.stats, canvasRef], () => {
    if (!canvasRef.value) {
      console.warn("Canvas element not found!");
      return;
    }

    if (bar.value) {
      bar.value.destroy();
      bar.value = null;
    }

    const context = canvasRef.value.getContext('2d');
    if (!context) {
      console.error("Unable to get canvas context!");
      return;
    }

    bar.value = new Chart(context, {
      type: 'bar',
      data: chartData(props.stats),
      options: {
        scales: {
          y: {
            beginAtZero: true,
            max: Math.max(...Object.values(props.stats)) + 1,
          }
        }
      }
    });
  });
});
</script>

<style scoped>

</style>