import {t, Traduction} from "@/utils/Traduction";

const enumeration = {};

enumeration.countryAvailable = [
    {id: "fr", display: "french"},
    {id: "en", display: "english"},
    {id: "hi", display: "hindi"},
    {id: "zh", display: "chinese"},
    {id: "es", display: "spanish"},
    {id: "ar", display: "arabic"},
    {id: "bn", display: "bengali"},
    {id: "pt", display: "portuguese"},
    {id: "ru", display: "russian"},
    {id: "ur", display: "urdu"}
]

enumeration.role = {
    ADMIN: "admin",
    CLIENT: "client",
}

enumeration.etat = {
    COMMANDE: "Commande",
    OBJET: "Objet"
};

enumeration.etatCommande = {
    A_FAIRE: "À faire",
    EN_COURS: "En cours",
    TERMINEE: "Terminée",
    TERMINEE_ET_PAYEE: "Terminée et payée",
    ANNULEE: "Annulée",
};

enumeration.etatObjet = {
    A_IMPRIMER: "À imprimer",
    EN_IMPRESSION: "En impression",
    IMPRIMEE: "Imprimé",
    ANNULE: "Annulé",
}

enumeration.getTraductionByEtat = (name) => {
    if(name === Traduction.fr.todo) return t("todo");
    if(name === Traduction.fr.wip) return t("wip");
    if(name === Traduction.fr.ended) return t("ended");
    if(name === Traduction.fr.endedAndPaid) return t("endedAndPaid");
    if(name === Traduction.fr.cancel) return t("cancel");
    if(name === Traduction.fr.todoPrint) return t("todoPrint");
    if(name === Traduction.fr.printing) return t("printing");
    if(name === Traduction.fr.printed) return t("printed");
    if(name === Traduction.fr.printCancel) return t("printCancel");
}

enumeration.typeVersion = {
    MAJEURE: "Majeur",
    MINEURE: "Mineur",
    FIX: "Fix"
}

enumeration.constant = {
    COEFICIENT: 1.25,
}

export { enumeration };