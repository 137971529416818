<template>
  <form class="flex flex-col gap-4">

    <InputField :placeholder="t('newMail')" type="email" v-model="email" showLabel />
    <InputField :placeholder="t('newPassword')" type="password" v-model="password" showLabel />
    <InputField :placeholder="t('confirmPassword')" type="password" v-model="confirmPassword" showLabel />
    <InputField :placeholder="t('newDelivreryAddress')" v-model="livraison" showLabel />
    <DropDownField :placeholder="t('newLanguage')" :values="enumeration.countryAvailable" :label="t('language')"
       v-model="langue" translateValues showLabel/>

    <BlueButton @click="editUser" :text="t('editAction')" />
  </form>
</template>

<script setup>

import {t} from "@/utils/Traduction";
import {enumeration} from "../../../Enumeration";
import BlueButton from "@/components/molecules/BlueButton.vue";
import DropDownField from "@/components/molecules/DropDownField.vue";
import InputField from "@/components/molecules/InputField.vue";
import {ref} from "vue";
import xhr from "@/utils/xhr";
import {jsonValidator} from "@/utils/JsonValidator";
import {router} from "@/routeur";
import {NotificationStores} from "@/stores/NotificationStores";
import {UtilisateurStores} from "@/stores/UtilisateurStores";

const email = ref('');
const password = ref('');
const confirmPassword = ref('');
const livraison = ref('');
const langue = ref(-1);

const notificationStores = NotificationStores();
const utilisateurStore = UtilisateurStores();

const editUser = async () => {

  if(email.value === '' && password.value === '' && confirmPassword.value === '' && livraison.value === '' && langue.value === -1) {
    notificationStores.addNotification(
        t("youNeedToFillOneField"),
        "error");
    return;
  }

  if(password.value !== confirmPassword.value) {
    notificationStores.addNotification(
        t("passwordNotMatch"),
        "error");
    return;
  }

  const changedData = {};

  if(email.value !== '') {
    changedData.email = email.value;
  }

  if(password.value !== '' && confirmPassword.value !== '') {
    changedData.password = password.value;
    changedData.confirmPassword = confirmPassword.value;
  }

  if(livraison.value !== '') {
    changedData.livraison = livraison.value;
  }

  if(langue.value !== '') {
    changedData.langue = langue.value;
  }

  xhr.addRequestToQueue("PUT", "/utilisateurs/update", changedData, true, true, false,
      async (response) => {
        if(response.code !== 200) {

          if(response.code === 0 || !jsonValidator.validate(response.response)) {
            notificationStores.addNotification(
                t("errorDuringServerConnexion"),
                "error");
          } else {
            notificationStores.addNotification(
                JSON.parse(response.response).message,
                "error");
          }
        } else {
          notificationStores.addNotification(
              t("personalInfoEditNeedToReconnect"),
              "success");

          utilisateurStore.logout();
          await router.push({ path: "/" });
        }
      });
}
</script>

<style scoped>

</style>