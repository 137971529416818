<template>
  <div class="w-full lg:w-auto">
    <LabelInput :class="{'hidden': !showLabel}" :for="placeholder" :label="label" />
    <select
        v-model="value"
        :id="placeholder"
        class="mt-1 px-2 block w-full rounded border border-gray-400 py-2"
        :required="required">

      <option v-if="placeholder" value="-1" disabled>{{placeholder}}</option>
      <option v-for="option in values" :value="option.id" :key="option.id">{{translateValues ? t(option.display) : option.display}}</option>
    </select>
  </div>
</template>

<script setup>

import {defineEmits, defineModel, defineProps, ref, watch} from "vue";
import {t} from "@/utils/Traduction";
import LabelInput from "@/components/atoms/LabelInput.vue";

const props = defineProps({
  values: {
    type: Array,
    required: true,
  },
  placeholder: {
    type: String,
    required: true,
  },
  label: {
    type: String,
  },
  translateValues: {
    type: Boolean,
    default: false,
  },
  showLabel: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue", "change"]);
const model = defineModel({type: [String, Number]});
const value = ref(model.value);

watch(() => model.value, (newValue) => {
  value.value = newValue;
});

watch(value, (newValue) => {
  emit("update:modelValue", newValue);
  emit("change");
});

const label = props.label || props.placeholder;

</script>

<style scoped>

</style>