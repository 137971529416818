<template>
  <div class="flex items-center border gap-2 border-blue-500 rounded-full overflow-hidden mt-2 ">
    <button @click="emits('removeOne')" class="w-10 h-10 text-white bg-blue-500 hover:bg-blue-800 focus:outline-none">-</button>
    <SimpleText :content="value" />
    <button @click="emits('addOne')" class="w-10 h-10 text-white bg-blue-500 hover:bg-blue-800 focus:outline-none">+</button>
  </div>
</template>

<script setup>

import {defineEmits, defineProps} from "vue";
import SimpleText from "@/components/atoms/SimpleText.vue";

defineProps({
  value: {
    type: Number,
    required: true,
  }
})

const emits = defineEmits(['addOne', 'removeOne']);


</script>


<style scoped>

</style>