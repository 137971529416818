<template>
  <label :for="props.for" class="block text-gray-700 text-sm font-medium">{{props.label}}</label>
</template>

<script setup>

import {defineProps} from "vue";

const props = defineProps({
  for: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  }
})
</script>

<style scoped>

</style>