<template>
  <div class="w-full">
    <LabelInput :label="label" :for="placeholder" :class="{'hidden': !showLabel}" />
    <textarea :placeholder="placeholder" class="mt-1 border w-full border-gray-400 px-2 rounded py-1"
        :class="{ 'h-32' : !removeHeight}" v-model="value" :id="placeholder"></textarea>
  </div>
</template>

<script setup>

import {defineEmits, defineModel, defineProps, ref, watch} from "vue";
import LabelInput from "@/components/atoms/LabelInput.vue";

defineProps({
  placeholder: {
    type: String,
    default: ""
  },
  label: {
    type: String,
    default: ""
  },
  showLabel: {
    type: Boolean,
    default: false
  },
  removeHeight: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["update:modelValue", "change"]);
const model = defineModel({type: [String, Number]});
const value = ref(model.value);

watch(() => model.value, (newValue) => {
  value.value = newValue;
});

watch(value, (newValue) => {
  emit("update:modelValue", newValue);
  emit("change");
});

</script>

<style scoped>

</style>