<template>
  <div class="w-full flex flex-row justify-center">
    <div :class="$attrs.class">
      <SimpleText :content="titre" />
      <canvas ref="canvas" width="100%" height="100%" class="mt-4"></canvas>
    </div>
  </div>
</template>

<script setup>

import {defineProps, onUnmounted, ref, useTemplateRef, watch} from 'vue'
import SimpleText from "@/components/atoms/SimpleText.vue";
import {Chart} from "chart.js";

const props = defineProps({
  titre: {
    type: String,
    required: true
  },
  stats: {
    type: Array,
    required: true
  }
});

const canvasRef = useTemplateRef("canvas");
const pie = ref(null);

watch([() => props.stats, canvasRef], () => {
  if(!canvasRef.value) {
    return;
  }

  if(pie.value) {
    pie.value.destroy();
    pie.value = null;
  }

  let data = {
    datasets: [{
      data: props.stats.map((stats) => stats.value),
      backgroundColor: props.stats.map((stat) => stat.color)
    }],
    labels: props.stats.map((stat) => stat.text)
  };

  let config = {
    type: 'pie',
    data: data,
    options: {
      responsive: true, plugins: {legend: {display: false}}},
  };

  pie.value = new Chart(canvasRef.value, config);
})

onUnmounted(() => {
  if(pie.value) {
    pie.value.destroy();
  }
})
</script>

<style scoped>

</style>