<template>
  <div>
    <LabelInput :label="fileName === '' ? t('chooseAStl') : fileName" :for="forId"
      class="bg-purple-500 p-3 w-full text-center text-white rounded hover:bg-purple-700 active:bg-purple-900 whitespace-nowrap text-ellipsis overflow-x-hidden cursor-pointer"/>

    <input
        type="file"
        @change="onFileChange"
        :id="forId"
        class="hidden"
        accept=".stl"
    />
  </div>
</template>

<script setup>
import {defineEmits, defineProps, ref} from "vue";
import {t} from "@/utils/Traduction";
import LabelInput from "@/components/atoms/LabelInput.vue";

const props = defineProps({
  defaultValue: {
    type: String,
    default: ''
  }
})

const emits = defineEmits(["update:modelValue", "change"])

const forId = crypto.randomUUID();
const fileName = ref(props.defaultValue);

const onFileChange = (event) => {
  const file = event.target.files[0]
  fileName.value = file.name;

  const reader = new FileReader();
  reader.readAsDataURL(file);


  reader.onload = () => {
    emits('update:modelValue', {name: file.name, content: reader.result});
    emits('change');
  };
}


</script>


<style scoped>

</style>