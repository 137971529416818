<template>
  <BaseDeleteModal
      :text="t('attentionAlertDeletePrinter') + ' ' + selectedImprimante.marque + ' ' +
       selectedImprimante.modele + t('attentionAlertDeletePrinterEnd')" :isVisible="showModalDel"
      @closeModal="onCloseModalDelete"/>

  <CreatePrinterForm class="mt-4 mx-4" />

  <div class="flex flex-row flex-wrap gap-8 w-full justify-center my-8">
    <IdCardImprimante v-for="imprimante in imprimanteStore.imprimantes" :key="imprimante.uuid" :imprimante="imprimante"
      :show-modal-delete="showModalDelete" />
  </div>
</template>

<script setup>
import {enumeration} from "../../Enumeration";
import {t} from "@/utils/Traduction";
import {router} from "@/routeur";
import {NotificationStores} from "@/stores/NotificationStores";
import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {ImprimantesStore} from "@/stores/ImprimanteStore";
import IdCardImprimante from "@/components/organismes/IdCardImprimante.vue";
import {ref} from "vue";
import CreatePrinterForm from "@/components/organismes/CreatePrinterForm.vue";
import BaseDeleteModal from "@/components/molecules/BaseDeleteModal.vue";

const notificationStore = NotificationStores();
const utilisateurStore = UtilisateurStores();
const imprimanteStore = ImprimantesStore()

if(utilisateurStore.role !== enumeration.role.ADMIN) {
  utilisateurStore.logout(false);
  notificationStore.addNotification(t('youNeedToBeAdminToAccessThisPage'), 'error');
  router.push({ path: "/" });
}

imprimanteStore.loadImprimantes();

const showModalDel = ref(false);
const selectedImprimante = ref({marque: "", modele: ""});

const showModalDelete = (imprimante) => {
  selectedImprimante.value = imprimante;
  showModalDel.value = true;
}

const onCloseModalDelete = (deleteImprimante) => {
  if(deleteImprimante) {
    imprimanteStore.deleteImprimante(selectedImprimante.value.uuid);
  }
  showModalDel.value = false;
  selectedImprimante.value = {marque: "", modele: ""};
}

</script>

<style scoped>

</style>