<template>
  <form @submit.prevent="send" class="flex flex-col items-center gap-4">
    <div class="flex flex-row gap-4">
      <InputField :placeholder="t('youtFirstname')" :label="t('firstname')" v-model="prenom" required showLabel/>
      <InputField :placeholder="t('yourLastname')" :label="t('lastname')" v-model="nom" required showLabel/>
    </div>

    <InputField :placeholder="t('yourEmail')" :label="t('email')" v-model="email" class="!w-full" required showLabel/>
    <TextAreaInput :placeholder="t('yourMessage')" :label="t('message')" v-model="message" required showLabel/>

    <BlueButton type="submit" class="w-full" :text="t('send')"/>
  </form>
</template>

<script setup>

import {ref} from "vue";
import xhr from "@/utils/xhr";
import {jsonValidator} from "@/utils/JsonValidator";
import {t} from "@/utils/Traduction";
import {NotificationStores} from "@/stores/NotificationStores";
import InputField from "@/components/molecules/InputField.vue";
import TextAreaInput from "@/components/molecules/TextAreaInput.vue";
import BlueButton from "@/components/molecules/BlueButton.vue";

const notificationStore = NotificationStores();

const prenom = ref("");
const nom = ref("");
const email = ref("");
const message = ref("");

const send = async () => {

  xhr.addRequestToQueue("POST", "/contacts", {prenom: prenom.value, nom: nom.value, email: email.value, message: message.value},
      false, true, false, (response) => {
        if(response.code !== 201) {
          if(response.code === 0 || !jsonValidator.validate(response.response)) {
            notificationStore.addNotification(
                t("errorDuringSendMessage"),
                "error");
          } else {
            notificationStore.addNotification(
                JSON.parse(response.response).message,
                "error");
          }
        } else {
          notificationStore.addNotification(
              t("messageSent"),
              "success");

          prenom.value = "";
          nom.value = "";
          email.value = "";
          message.value = "";
        }
      });
}
</script>

<style scoped>

</style>