<template>
  <button :type="type" class="bg-etat-encours hover:bg-[#ce8600] text-white font-bold py-2 px-4 rounded">{{ props.text }}</button>
</template>

<script setup>

import {defineProps} from "vue";

const props = defineProps({
  text: {
    type: String,
    required: true
  },
  type: {
    type: String,
    default: 'button'
  }
})
</script>


<style scoped>

</style>