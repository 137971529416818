<template>
  <BaseDeleteModal :text='t("AlertDeleteBrand")' :isVisible="marqueStore.showModalDelete" @closeModal="closeModalDelete"/>

  <CreateBrandForm />

  <div class="flex flex-wrap mx-8 mb-8">
    <IdCardMarque v-for="marque in marqueStore.marques" :key="marque.uuid" :marque="marque"
      :show-modal-delete="showModalDelete" />
  </div>

  <TitreUn v-if="marqueStore.marques.length === 0" :content='t("noBrand")'
     class="absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%]"/>
</template>

<script setup>

import {ref} from "vue";
import {MarqueStore} from "@/stores/MarqueStore";
import IdCardMarque from "@/components/organismes/IdCardMarque.vue";
import {NotificationStores} from "@/stores/NotificationStores";
import {t} from "@/utils/Traduction";
import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {enumeration} from "../../Enumeration";
import {router} from "@/routeur";
import CreateBrandForm from "@/components/organismes/CreateBrandForm.vue";
import TitreUn from "@/components/atoms/TitreUn.vue";
import BaseDeleteModal from "@/components/molecules/BaseDeleteModal.vue";

const marqueStore = MarqueStore();
const notificationStores = NotificationStores();
const utilisateurStore = UtilisateurStores();

if(utilisateurStore.role !== enumeration.role.ADMIN) {
  utilisateurStore.logout(false);
  notificationStores.addNotification(t("youNeedToBeAdminToAccessThisPage"), 'error');
  router.push({ path: "/" });
}


marqueStore.loadMarques();

const uuidMarqueModale = ref(0);

const closeModalDelete = (supprimer) => {
  if(supprimer) {
    marqueStore.deleteMarque(uuidMarqueModale.value);
  }

  marqueStore.showModalDelete = false;
}

const showModalDelete = (uuid) => {
  uuidMarqueModale.value = uuid;
  marqueStore.showModalDelete = true;
}
</script>

<style scoped>

</style>