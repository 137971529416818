<template>
  <BaseDeleteModal :text="t('cautionDeleteVersion')" :isVisible="showDeleteModal" @closeModal="deleteVersion"/>

  <div class="mx-4 md:mx-32 mt-8">
    <div class="flex flex-row justify-between items-center">
      <div class="flex flex-row items-center gap-2">
        <TitreDeux :content="t('version') + ' ' + version.version"/>
        <SimpleText :content="version.date" color="text-gray-400" />
      </div>

      <div v-if="utilisateurStore.role === enumeration.role.ADMIN" class="flex flex-row gap-2">
        <OrangeEditIcon v-show="!isEditMode" @click="setEditMode()"/>
        <GreenCheckIcon v-show="isEditMode" @click="sendEdit()"/>
        <RedTrashIcon @click="showDeleteModal = true"/>
      </div>
    </div>

    <SimpleText v-show="!isEditMode" :content="version.description" dangerouslySetInnerHTML class="ml-4" />
    <TextAreaInput v-show="isEditMode" v-model="editDescription" :placeholder="t('versionDescription')" showLabel/>

    <BlueSpacer />
  </div>
</template>

<script setup>

import {defineProps, ref} from 'vue';
import {enumeration} from "../../../Enumeration";
import xhr from "@/utils/xhr";
import {NotificationStores} from "@/stores/NotificationStores";
import {VersionStore} from "@/stores/VersionStore";
import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {jsonValidator} from "@/utils/JsonValidator";
import {t} from "@/utils/Traduction";
import TitreDeux from "@/components/atoms/TitreDeux.vue";
import SimpleText from "@/components/atoms/SimpleText.vue";
import OrangeEditIcon from "@/components/atoms/OrangeEditIcon.vue";
import GreenCheckIcon from "@/components/atoms/GreenCheckIcon.vue";
import RedTrashIcon from "@/components/atoms/RedTrashIcon.vue";
import BlueSpacer from "@/components/atoms/BlueSpacer.vue";
import TextAreaInput from "@/components/molecules/TextAreaInput.vue";
import BaseDeleteModal from "@/components/molecules/BaseDeleteModal.vue";

const props = defineProps({
  version: Object
});

const notificationStore = new NotificationStores();
const versionStore = new VersionStore();
const utilisateurStore = new UtilisateurStores();

const showDeleteModal = ref(false);
const isEditMode = ref(false);
const editDescription = ref(props.version.description);
editDescription.value = versionStore.unformatDescription(editDescription.value);

const deleteVersion = async (needDelete) => {
  if(!needDelete) {
    showDeleteModal.value = false;
    return;
  }

  xhr.addRequestToQueue("DELETE", "/versions/" + props.version.uuid, undefined, true, true, false,
    (response) => {
      if (response.code !== 204) {
        if (response.code === 0 || !jsonValidator.validate(response.response)) {
          notificationStore.addNotification(
              t("errorDuringDeleteVersion"),
              "error");
        } else {
          notificationStore.addNotification(
              JSON.parse(response.response).message,
              "error");
        }
      } else {
        notificationStore.addNotification(
            t("versionDeleted"),
            "success");

        versionStore.deleteVersion(props.version);
      }
    });
}

const setEditMode = () => {
  isEditMode.value = true;
}

const sendEdit = async () => {

  xhr.addRequestToQueue("PUT", "/versions/" + props.version.uuid, {description: editDescription.value},
  true, true, false, (response) => {
    if (response.code !== 204) {
      if (response.code === 0 || !jsonValidator.validate(response.response)) {
        notificationStore.addNotification(
          t("errorDuringEditVersion"),
          "error");
      } else {
        notificationStore.addNotification(
          JSON.parse(response.response).message,
          "error");
      }
    } else {
      notificationStore.addNotification(
        t("versionEdited"),
        "success");
    }
  });

  isEditMode.value = false;
  versionStore.updateDescription(props.version, editDescription.value);
}

</script>


<style scoped>

</style>