<template>
   <BaseIdCard :numberPerRow="4">
     <BaseKeyValue :cles="t('uuid')">
       <SimpleText :content="props.marque.uuid" />
     </BaseKeyValue>

     <BaseKeyValue :cles="t('brandName')" >
       <SimpleText :content="props.marque.nom" v-show="!isEditing" />
       <InputField :placeholder="t('brandName')"  v-show="isEditing" v-model="editName"/>
     </BaseKeyValue>

      <div class="mt-4 flex flex-row gap-2 justify-end">
        <GreenCheckIcon v-show="isEditing" @click="onEdit" />
        <OrangeEditIcon v-show="!isEditing" @click="() => {isEditing = true;}" />
        <RedTrashIcon @click="onDelete" />
      </div>
   </BaseIdCard>
</template>

<script setup>

import {t} from "@/utils/Traduction";
import {defineProps, ref} from 'vue';
import {NotificationStores} from "@/stores/NotificationStores";
import {MarqueStore} from "@/stores/MarqueStore";
import GreenCheckIcon from "@/components/atoms/GreenCheckIcon.vue";
import OrangeEditIcon from "@/components/atoms/OrangeEditIcon.vue";
import RedTrashIcon from "@/components/atoms/RedTrashIcon.vue";
import BaseIdCard from "@/components/molecules/BaseIdCard.vue";
import BaseKeyValue from "@/components/molecules/BaseKeyValue.vue";
import SimpleText from "@/components/atoms/SimpleText.vue";
import InputField from "@/components/molecules/InputField.vue";

const props = defineProps({
  marque: Object,
  showModalDelete: Function
});

const notificationStores = NotificationStores();
const marqueStore = MarqueStore();

const isEditing = ref(false);
const editName = ref(props.marque.nom);


const onDelete = () => {
  props.showModalDelete(props.marque.uuid);
}

const onEdit = () => {
  if(editName.value === "") {
    notificationStores.addNotification(t("messageCannotBeEmpty"), 'error');
    return;
  }

  marqueStore.editMarque(editName.value, props.marque.uuid);
  isEditing.value = false;
}

</script>

<style scoped>

</style>