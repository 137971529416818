<template>
  <div>
    <InfoChip class="py-1 px-2 !w-fit" :couleur="couleur" :text="text" />
  </div>
</template>

<script setup>

import {computed, defineProps} from 'vue';
import {enumeration} from '../../../Enumeration';
import InfoChip from "@/components/molecules/InfoChip.vue";

const props = defineProps({
  etat: Object
});

const text = computed(() => enumeration.getTraductionByEtat(props.etat.libelle));
const couleur = computed(() => {
  // etat de la commande est A_FAIRE ou l'objet est A_IMPRIMER
  if(props.etat.libelle === enumeration.etatCommande.A_FAIRE || props.etat.libelle === enumeration.etatObjet.A_IMPRIMER) {
    return "bg-etat-afaire";

  // etat de la commande est EN_COURS ou l'objet est EN_IMPRESSION
  } else if(props.etat.libelle === enumeration.etatCommande.EN_COURS || props.etat.libelle === enumeration.etatObjet.EN_IMPRESSION) {
    return "bg-etat-encours";

  // etat de la commande est
  } else if(props.etat.libelle === enumeration.etatCommande.TERMINEE || props.etat.libelle === enumeration.etatObjet.IMPRIMEE) {
    return "bg-etat-termine";

  // etat de la commande est TERMINEE_ET_PAYEE
  } else if(props.etat.libelle === enumeration.etatCommande.TERMINEE_ET_PAYEE) {
    return "bg-etat-termine-et-paye";

  // etat de la commande est ANNULEE ou l'objet est ANNULE
  } else if(props.etat.libelle === enumeration.etatCommande.ANNULEE || props.etat.libelle === enumeration.etatObjet.ANNULE) {
    return "bg-etat-annulee";
  } else {
    return ""
  }
})




</script>

<style scoped>

</style>