<template>
  <div class="flex flex-col lg:flex-row items-between gap-4 m-4">
    <InputField :placeholder="t('newLabelTypeOfFilament')" v-model="newLibelle" />
    <BlueButton :text="t('addAction')" @click="createTypeFilament" />
  </div>
</template>

<script setup>

import {t} from "@/utils/Traduction";
import {ref} from "vue";
import {NotificationStores} from "@/stores/NotificationStores";
import {TypeFilamentStore} from "@/stores/TypeFilamentStore";
import InputField from "@/components/molecules/InputField.vue";
import BlueButton from "@/components/molecules/BlueButton.vue";

const notificationStores = NotificationStores();
const typeFilamentStore = TypeFilamentStore();

const newLibelle = ref("");

const createTypeFilament = () => {
  if(newLibelle.value === "") {
    notificationStores.addNotification(t("needFillBrandName"), 'error');
    return;
  }

  typeFilamentStore.createTypeFilament(newLibelle.value);
  newLibelle.value = "";
}
</script>

<style scoped>

</style>