<template>
  <div class="flex flex-col justify-start items-start bg-white p-8 rounded-xl mt-4 w-full md:w-[48%] lg:w-[23%] gap-4"
       :class="{'border-red-600 border-2': model.error}">

    <InputField :placeholder="t('inputProductName')" :label="t('productName')" showLabel
          v-model="localModel.nom" required class="!w-full" @onChange="resetError" />

    <div class="w-full">
      <DropDownField :placeholder="t('selectAColor')" :label="t('color')" required v-model="localModel.couleur" class="!w-full"
             :values="couleurStore.couleurs.map(c => ({id: c.uuid, display: c.libelle}))" showLabel @change="resetError" />

      <BlueClickableText :text="t('addColor')" @click="createCouleur"/>
    </div>
    <InputField :placeholder='t("inputQuantity")' :label="t('quantity')" required v-model="localModel.quantite"
            showLabel class="!w-full" type="number" :min="1" @onChange="resetError"/>

    <DropDownField placeholder="" :label="t('filling')" required v-model="localModel.remplissage" class="!w-full"
           :values="utils.getFillingValues()" showLabel @change="resetError" />

    <PurpleFileInputField v-model="localModel.fichier" :defaultValue="localModel.fichier.name" @change="resetError" class="w-full" />

    <BlueButton :text="t('show3D')" @click="emit('show3DRenderModal', localModel.fichier.content)" v-show="localModel.fichier.content !== ''" class="w-full" />

    <RedButton :text="t('deleteAProduct')" @click="emit('removeProduit', localModel.uuid);" class="w-full" />
  </div>
</template>

<script setup>

import {defineEmits, defineProps, ref, watch} from "vue";
import {CouleurStore} from "@/stores/CouleurStore";
import {t} from "@/utils/Traduction";
import InputField from "@/components/molecules/InputField.vue";
import DropDownField from "@/components/molecules/DropDownField.vue";
import BlueClickableText from "@/components/molecules/BlueClickableText.vue";
import PurpleFileInputField from "@/components/molecules/PurpleFileInputField.vue";
import BlueButton from "@/components/molecules/BlueButton.vue";
import RedButton from "@/components/molecules/RedButton.vue";
import {utils} from "@/utils/other";

const emit = defineEmits(["update", "removeProduit", "show3DRenderModal"]);
const props = defineProps({
  model: {
    type: Object,
    required: true
  }
});

const localModel = ref(props.model);

watch(() => props.model, (newVal) => {
  localModel.value = newVal;
});

const resetError = () => {
  localModel.value.error = false;
}

const couleurStore = CouleurStore();

couleurStore.loadCouleurs();

const createCouleur = () => {
  couleurStore.showModalCreation = true;
}

</script>

<style scoped>

</style>