<template>
  <div>
    <SimpleText :content="titre" />

    <div>
      <canvas ref="canvas" width="100%" height="75%"></canvas>
    </div>
  </div>
</template>

<script setup>

import SimpleText from "@/components/atoms/SimpleText.vue";
import {defineProps, onMounted, ref, useTemplateRef, watch} from "vue";
import {utils} from "@/utils/other";
import {Chart} from "chart.js";

const props = defineProps({
  titre: {
    type: String,
    required: true
  },
  stats: {
    type: Array,
    required: true
  }
});

const canvasRef = useTemplateRef("canvas");
const bar = ref(null);

onMounted(() => {
  watch(() => props.stats, () => {

    if (bar.value) {
      bar.value.destroy();
      bar.value = null;
    }

    const dataset = {
      label: props.titre,
      data: props.stats.map((stat) => stat.value),
      backgroundColor: props.stats.map(() => utils.getRandomColorHexa())
    };

    let config = {
      type: 'bar',
      data: {
        labels: props.stats.map((stat) => stat.text),
        datasets: [dataset]
      },
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        responsive: true,
        indexAxis: "y",
        scales: {
          x: {
            ticks: {
              min: 0,
              beginAtZero: true,
            },
            max: Math.max(...props.stats.map((imprimante) => imprimante.value)) + 1,
          }
        }
      }
    };

    bar.value = new Chart(
        canvasRef.value,
        config
    );

  });
})

</script>

<style scoped>

</style>