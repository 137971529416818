<template>
  <h1 class="text-2xl font-semibold">{{props.content}}</h1>
</template>

<script setup>

import {defineProps} from 'vue';

const props = defineProps({
  content: {
    type: String,
    required: true
  }
})

</script>

<style scoped>

</style>