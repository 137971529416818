<template>
  <div class="p-2" :class="style">
    <div class="bg-white shadow-xl rounded-lg p-4 flex flex-col justify-center h-full relative" :class="innerStyle">
      <slot />
    </div>
  </div>
</template>

<script setup>

import {defineProps, ref} from 'vue';

const props = defineProps({
  numberPerRow: {
    type: Number,
    default: 3,
    validator(value) {
      return [1, 2, 3, 4].includes(value)
    }
  },
  innerClass: {
    type: String,
    default: ""
  },
  border: {
    type: String,
    required: false
  }
});

const style = {
  "lg:w-1/4 md:w-1/2 w-full": props.numberPerRow === 4,
  "lg:w-1/3 md:w-1/2 w-full": props.numberPerRow === 3,
  "lg:w-1/2 w-full": props.numberPerRow === 2,
  "w-full": props.numberPerRow === 1
}

const innerStyle = ref(props.innerClass);

if(props.border) {
  innerStyle.value += ` border-2 ${props.border}`;
}

</script>

<style scoped>

</style>