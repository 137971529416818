<template>
  <div class="flex flex-col lg:flex-row items-between gap-4 m-4">
    <TextAreaInput :placeholder="t('informationMessage')" v-model="newMessage" class="!w-auto" removeHeight />
    <InputField placeholder="" v-model="newEndDate" type="datetime-local"/>
    <BlueButton :text="t('addAction')" @click="createInformation" />
  </div>
</template>

<script setup>
import {t} from "@/utils/Traduction";
import BlueButton from "@/components/molecules/BlueButton.vue";
import InputField from "@/components/molecules/InputField.vue";
import TextAreaInput from "@/components/molecules/TextAreaInput.vue";
import {ref} from "vue";
import {NotificationStores} from "@/stores/NotificationStores";
import {InformationStore} from "@/stores/InformationStore";

const notificationStores = NotificationStores();
const informationStore = InformationStore();

const newMessage = ref("");
const newEndDate = ref("");

const createInformation = () => {
  if(newMessage.value === "") {
    notificationStores.addNotification(t("messageCannotBeEmpty"), 'error');
    return;
  }

  if(newEndDate.value === "") {
    newEndDate.value = null;
  }

  informationStore.createInformation(newMessage.value, newEndDate.value);
  newMessage.value = "";
  newEndDate.value = "";
}
</script>

<style scoped>

</style>