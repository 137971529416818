<template>
  <div class="flex flex-row flex-wrap justify-evenly mt-8 mx-4 md:mx-0 mb-8 md:mb-16">
    <IdCardContactComponent v-for="message of contactStore.contact" :key="message.uuid" :message="message"/>
  </div>

  <TitreUn v-if="contactStore.contact.length === 0" :content='t("noMessage")'
           class="absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%]"/>
</template>

<script setup>

import IdCardContactComponent from "@/components/organismes/IdCardContactComponent.vue";
import {ContactStores} from "@/stores/ContactStore";
import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {router} from "@/routeur";
import {NotificationStores} from "@/stores/NotificationStores";
import {enumeration} from "../../Enumeration";
import {t} from "@/utils/Traduction";
import TitreUn from "@/components/atoms/TitreUn.vue";

const utilisateurStore = UtilisateurStores();
const notificationStores = NotificationStores();

if(utilisateurStore.role !== enumeration.role.ADMIN) {
  utilisateurStore.logout(false);
  notificationStores.addNotification(t("youNeedToBeAdminToAccessThisPage"), 'error');
  router.push({ path: "/" });
}

const contactStore = ContactStores();

if(contactStore.contact.length === 0) {
  contactStore.loadContacts();
}

</script>

<style scoped>

</style>