<template>
  <div class="w-full flex flex-row justify-center items-center" :class="{ [props.couleur]: true,
   'rounded-lg': rounded}">
    <span class="text-white">{{text}}</span>
  </div>
</template>

<script setup>

import {defineProps} from "vue";

const props = defineProps({
  couleur: {
    type: String,
    required: true
  },
  text: {
    type: String,
    required: true
  },
  rounded: {
    type: Boolean,
    default: true
  }
})

</script>


<style scoped>

</style>