<template>
  <InformationsBar v-show="information.show === true" v-for="information in informationStore.activeInformations" :key="information.uuid" :information="information" />

  <TitreUn v-if="!hasCommande" :content='t("youDontHaveOrder")'
         class="absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%]"/>

  <div class="flex flex-col items-center my-8 gap-4">
    <BaseKeyValue :cles="t('leftToPay')" v-show="isAdminAndHasUpayedCommande">
      <SimpleText :content="commandeStores.unpayedAmount + '€'" />
    </BaseKeyValue>

    <div class="flex flex-wrap justify-center mx-8 w-full">
      <IdCardCommande v-for="commande in commandeStores.commandes" :key="commande.uuid" :commande="commande"
        :isMultiOrderInvoceMode="false" :toogleOrderToMultiOrderInvoice="() => {}" />
    </div>

    <BlueButton v-show="hasMoreCommande" @click="commandeStores.getCommandeFromOffset()" class="w-1/2" :text='t("showMore")' />
  </div>
</template>

<script setup>

import {CommandeStores} from "@/stores/CommandeStores";
import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {enumeration} from "../../Enumeration";
import {InformationStore} from "@/stores/InformationStore";
import InformationsBar from "@/components/molecules/InformationsBar.vue";
import {t} from "@/utils/Traduction";
import TitreUn from "@/components/atoms/TitreUn.vue";
import IdCardCommande from "@/components/organismes/IdCardCommande.vue";
import BlueButton from "@/components/molecules/BlueButton.vue";
import BaseKeyValue from "@/components/molecules/BaseKeyValue.vue";
import SimpleText from "@/components/atoms/SimpleText.vue";
import {computed} from "vue";

const commandeStores = CommandeStores();
const utilisateurStores = UtilisateurStores();
const informationStore = InformationStore();

const hasCommande = computed(() => commandeStores.commandes.length > 0);
const isAdmin = computed(() => utilisateurStores.role === enumeration.role.ADMIN);
const isAdminAndHasUpayedCommande = computed(() => isAdmin.value && commandeStores.unpayedAmount > 0);
const hasMoreCommande = computed(() => commandeStores.hasMore && commandeStores.commandes.length > 0);

if(commandeStores.commandes.length === 0) {
  commandeStores.offset = 0;
  commandeStores.hasMore = true;

  commandeStores.getCommandeFromOffset();
}

if(utilisateurStores.role === enumeration.role.CLIENT) {
  commandeStores.loadUnpayed();
}

informationStore.loadActiveInformations();

</script>

<style scoped>

</style>