<template>
  <div class="flex flex-col w-full lg:mx-8 gap-2">
    <DropDownField :placeholder="''" v-model="typeNouvelleVersion"
       :values="[
          {id: enumeration.typeVersion.MAJEURE, display: t('major')},
          {id: enumeration.typeVersion.MINEURE, display: t('minor')},
          {id: enumeration.typeVersion.FIX, display: t('fix')}
       ]"  />

    <TextAreaInput :placeholder="t('versionDescription')" v-model="nouvelleVersion" showLabel />
  </div>

  <div class="flex w-full justify-end mr-8 md:mr-0 md:ml-8 mt-4">
    <BlueButton :text="t('addVersion')" @click="sendNewVersion" />
  </div>
</template>

<script setup>

import {enumeration} from "../../../Enumeration";
import {t} from "@/utils/Traduction";
import xhr from "@/utils/xhr";
import {jsonValidator} from "@/utils/JsonValidator";
import {ref} from "vue";
import {NotificationStores} from "@/stores/NotificationStores";
import {VersionStore} from "@/stores/VersionStore";
import DropDownField from "@/components/molecules/DropDownField.vue";
import TextAreaInput from "@/components/molecules/TextAreaInput.vue";
import BlueButton from "@/components/molecules/BlueButton.vue";

const versionStore = new VersionStore();
const notificationStore = new NotificationStores();

const typeNouvelleVersion = ref(enumeration.typeVersion.FIX);
const nouvelleVersion = ref("");

const sendNewVersion = async () => {
  if(nouvelleVersion.value === "") {
    notificationStore.addNotification(
        t("needFillDescriptionForThisVersion"),
        "error");
  }

  xhr.addRequestToQueue("POST", "/versions", {description: nouvelleVersion.value,
    versionType: typeNouvelleVersion.value}, true, true, false, (response) => {
    if (response.code !== 201) {
      if (response.code === 0 || !jsonValidator.validate(response.response)) {
        notificationStore.addNotification(
            t("errorDuringCreateVersion"),
            "error");
      } else {
        notificationStore.addNotification(
            JSON.parse(response.response).message,
            "error");
      }
    } else {
      notificationStore.addNotification(
          t("versionCreated"),
          "success");

      versionStore.addVersion(JSON.parse(response.response));
      typeNouvelleVersion.value = enumeration.typeVersion.FIX;
      nouvelleVersion.value = "";
    }
  });
}

</script>

<style scoped>

</style>