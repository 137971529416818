<template>
  <form @submit.prevent="login" class="flex flex-col gap-4">
    <InputField :placeholder='t("yourEmail")' :label='t("email")' v-model="email" type="email" required showLabel />
    <InputField :placeholder='t("yourPassword")' :label='t("password")' v-model="password" type="password" required showLabel />

    <BlueButton :text="t('login')" type="submit" />
  </form>
</template>

<script setup>

import {t} from "@/utils/Traduction";
import {ref} from "vue";
import xhr from "@/utils/xhr";
import {jsonValidator} from "@/utils/JsonValidator";
import {jwtDecode} from "jwt-decode";
import {router} from "@/routeur";
import {enumeration} from "../../../Enumeration";
import {NotificationStores} from "@/stores/NotificationStores";
import {UtilisateurStores} from "@/stores/UtilisateurStores";
import InputField from "@/components/molecules/InputField.vue";
import BlueButton from "@/components/molecules/BlueButton.vue";

const notificationStore = NotificationStores();
const utilisateurStore = UtilisateurStores();

const email = ref("");
const password = ref("");

const login = async () => {

  xhr.addRequestToQueue("POST", "/utilisateurs/login", {email: email.value, password: password.value},
    false, false, false, async (response) => {
      if(response.code !== 200) {

        if(response.code === 0 || !jsonValidator.validate(response.response)) {
          notificationStore.addNotification(
              t("errorDuringServerConnexion"),
              "error");
        } else {
          notificationStore.addNotification(
              JSON.parse(response.response).message,
              "error");
        }
      } else {
        const info = JSON.parse(response.response);
        notificationStore.addNotification(
            info.message,
            "success");


        const jwtInfo = jwtDecode(info.token);

        utilisateurStore.login(jwtInfo.role, info.token, jwtInfo.langue);


        if(localStorage.getItem("redirectAfterLogin")) {
          await router.push({path: localStorage.getItem("redirectAfterLogin")});

        } else if(jwtInfo.role === enumeration.role.ADMIN) {
          await router.push({path: "/admin/commandes"});

        } else {
          await router.push({path: "/commandes"});
        }
      }
    });
};
</script>

<style scoped>

</style>