<template>
  <BaseIdCard :numberPerRow="4" innerClass="items-center">
    <img :src="props.imprimante.urlImage" :alt="'image pour l\'imprimante ' + imprimante.marque + ' ' + imprimante.modele" class="w-3/4">

    <div class="w-full flex justify-between">
      <div class="flex flex-col" v-show="!isEditing">
        <TitreUn :content="imprimante.modele" />
        <TitreDeux :content="imprimante.marque" fontSemiBold />
        <SimpleText :content="imprimante.consommation + ' ' + t('watts')" />
        <SimpleText :content="imprimante.prix + ' €'" />
      </div>

      <div class="flex flex-col gap-2 mr-2 w-full" v-show="isEditing">
        <InputField :placeholder="t('model')" v-model="newModel"/>
        <InputField :placeholder="t('brand')" v-model="newMarque"/>
        <InputField :placeholder="t('consommation')" v-model="newConsommation" type="number"/>
        <InputField :placeholder="t('price')" v-model="newPrice" type="number"/>
        <InputField :placeholder="t('urlImgPrinter')" v-model="newUrlImage"/>
      </div>

      <div class="flex flex-col items-center justify-end gap-2">
        <BlueRightArrowIcon @click="goToDetail()" />
        <OrangeEditIcon v-if="!isEditing" @click="setToEditMode()" />
        <GreenCheckIcon v-if="isEditing" @click="modifierImprimante()" />
        <RedTrashIcon @click="props.showModalDelete(imprimante)" />
      </div>
    </div>
  </BaseIdCard>

</template>

<script setup>
import {defineProps, ref} from 'vue';
import {t} from "@/utils/Traduction";
import {ImprimantesStore} from "@/stores/ImprimanteStore";
import {router} from "@/routeur";
import TitreUn from "@/components/atoms/TitreUn.vue";
import TitreDeux from "@/components/atoms/TitreDeux.vue";
import SimpleText from "@/components/atoms/SimpleText.vue";
import InputField from "@/components/molecules/InputField.vue";
import BlueRightArrowIcon from "@/components/atoms/BlueRightArrowIcon.vue";
import OrangeEditIcon from "@/components/atoms/OrangeEditIcon.vue";
import GreenCheckIcon from "@/components/atoms/GreenCheckIcon.vue";
import RedTrashIcon from "@/components/atoms/RedTrashIcon.vue";
import BaseIdCard from "@/components/molecules/BaseIdCard.vue";

const props = defineProps({
  imprimante: Object,
  showModalDelete: Function
});

const imprimanteStore = ImprimantesStore();

const isEditing = ref(false);
const newMarque = ref(props.imprimante.marque);
const newModel = ref(props.imprimante.modele);
const newConsommation = ref(props.imprimante.consommation);
const newPrice = ref(props.imprimante.prix);
const newUrlImage = ref(props.imprimante.urlImage);

function setToEditMode() {
  isEditing.value = true;
}

const modifierImprimante = () => {
  imprimanteStore.modifierImprimante(props.imprimante.uuid, newMarque.value, newModel.value, newConsommation.value, newPrice.value, newUrlImage.value,
      () => {
    isEditing.value = false;
  });
}

const goToDetail = async () => {
  await router.push({'path': '/admin/imprimantes/stats/' + props.imprimante.uuid});
}

</script>

<style scoped>
</style>
