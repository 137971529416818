<template>
  <BaseModal :isVisible="isVisible">
    <TitreUn :content="t('createARepair')" />

    <InputField :placeholder="t('repairLabel')" v-model="newLibelle"/>
    <InputField :placeholder="t('price')" v-model="newPrix" type="number" :min="0"/>

    <div class="flex flex-row-reverse justify-start gap-4">
      <BlueButton :text="t('validate')" @click="createRepair" />
      <BlackClickableText :text="t('cancelAction')" @click="closeModal(undefined)" />
    </div>
  </BaseModal>
</template>

<script setup>

import {defineProps, ref} from "vue";
import {t} from "@/utils/Traduction";
import {NotificationStores} from "@/stores/NotificationStores";
import TitreUn from "@/components/atoms/TitreUn.vue";
import InputField from "@/components/molecules/InputField.vue";
import BlueButton from "@/components/molecules/BlueButton.vue";
import BlackClickableText from "@/components/molecules/BlackClickableText.vue";
import BaseModal from "@/components/molecules/BaseModal.vue";

const notificationStore = NotificationStores();

const props = defineProps({
  closeModal: Function,
  isVisible: {
    type: Boolean,
    required: true,
  }
});

const newLibelle = ref("");
const newPrix = ref(0);

const createRepair = () => {
  if(newLibelle.value === "" || newPrix.value === 0) {
    notificationStore.addNotification(t("needFillAllFields"), 'error');
    return;
  }

  props.closeModal({libelle: newLibelle.value, prix: newPrix.value});

  newLibelle.value = "";
  newPrix.value = 0;
}

</script>

<style scoped>

</style>