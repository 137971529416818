<template>
  <BaseIdCard innerClass="!flex-row gap-4">
    <div class="flex flex-col items-center gap-2 justify-center flex-grow">
      <SimpleText v-if="!isEditing" :content="props.couleur.libelle" />
      <InfoChip text="" :style="{ backgroundColor: props.couleur.code }"  couleur=""
                v-show="!isEditing" :rounded="false" class="h-4 border border-black"/>

      <InputField :placeholder="t('colorName')" v-model="libelleValeur"
            v-show="isEditing" showLabel />
      <InputField placeholder="" v-model="codeValeur"
            v-show="isEditing" type="color" class="w-4/5 lg:w-full" />
    </div>

    <div class="flex flex-col gap-2">
      <OrangeEditIcon @click="isEditing = true" v-show="!isEditing" />
      <GreenCheckIcon @click="editCouleur" v-show="isEditing" />
      <RedTrashIcon @click="showDeleteModal = true" />
    </div>
  </BaseIdCard>

  <BaseDeleteModal :text='t("attentionAlertDeleteColor") + " " + props.couleur.libelle + " " + t("attentionAlertDeleteColorEnd")'
       :isVisible="showDeleteModal" @closeModal="closeModal" />
</template>

<script setup>

import {defineProps, ref} from 'vue';
import {CouleurStore} from "@/stores/CouleurStore";
import {NotificationStores} from "@/stores/NotificationStores";
import {t} from "@/utils/Traduction";
import SimpleText from "@/components/atoms/SimpleText.vue";
import InfoChip from "@/components/molecules/InfoChip.vue";
import InputField from "@/components/molecules/InputField.vue";
import OrangeEditIcon from "@/components/atoms/OrangeEditIcon.vue";
import GreenCheckIcon from "@/components/atoms/GreenCheckIcon.vue";
import RedTrashIcon from "@/components/atoms/RedTrashIcon.vue";
import BaseDeleteModal from "@/components/molecules/BaseDeleteModal.vue";
import BaseIdCard from "@/components/molecules/BaseIdCard.vue";

const couleurStore = CouleurStore();
const notificationStores = NotificationStores();

const props = defineProps({
    couleur: Object
});

const isEditing = ref(false);
const showDeleteModal = ref(false);
const libelleValeur = ref(props.couleur.libelle);
const codeValeur = ref(props.couleur.code);

const closeModal = (hasDelete) => {
    showDeleteModal.value = false;
    if(hasDelete) {
        couleurStore.deleteCouleur(props.couleur.uuid);
    }
}

const editCouleur = () => {
  if(libelleValeur.value.trim() === "") {
    notificationStores.addNotification(t('labelCannotBeEmpty'), 'error');
    return;
  }

  couleurStore.editCouleur(props.couleur.uuid, libelleValeur.value, codeValeur.value);
  isEditing.value = false;
}

</script>

<style scoped>

</style>