<template>
  <div class="flex flex-col justify-center mt-4 text-center">
    <TitreDeux :content="stats.value.utilisateur.email" fontSemiBold />
    <SimpleText :content="stats.value.utilisateur.livraison" color="text-gray-400" />
    <SimpleText :content="t(stats.value.utilisateur.role)" color="text-gray-400" />
  </div>

  <div class="flex flex-col md:flex-row flex-wrap items-center justify-center mx-4 md:mx-16 mt-4">

    <IdCardStats :titre="t('ordersOnly')"
       :stats="[
           {nom: t('numberOfOrder'), valeur: stats.value.nbCommandes},
           {nom: t('orderCancel'), valeur: stats.value.nbCommandesAnnuler}
       ]" />

    <IdCardStats :titre="t('products')"
       :stats="[
            {nom: t('numberOfProducts'), valeur: stats.value.nbPiecesTotal},
            {nom: t('avgProducts'), valeur: converter.round(stats.value.avgPiecesParCommande)}
       ]" />

    <IdCardStats :titre="t('weights')"
       :stats="[
            {nom: t('totalWeight'), valeur: stats.value.nbPoidsTotal + ' kg'},
            {nom: t('avgWeight'), valeur: converter.round(stats.value.avgPoids) + ' g'}
       ]" />

    <IdCardStats :titre="t('time')"
       :stats="[
            {nom: t('totalTime'), valeur: converter.minToDays(stats.value.nbTempsTotal)},
            {nom: t('avgTime'), valeur: converter.minToHours(stats.value.avgTemps)}
       ]" />

    <IdCardStats :titre="t('price')"
       :stats="[
            {nom: t('priceProduction'), valeur: converter.round(stats.value.prixProductionTotal) + ' €'},
            {nom: t('totalPrice'), valeur: converter.round(stats.value.prixTotal) + ' €'},
            {nom: t('leftToPay'), valeur: converter.round(stats.value.resteAPayer) + ' €'}
       ]" />
  </div>

  <div class="w-full flex flex-col lg:flex-row items-center justify-center mt-8 px-16">
    <PieStats :titre="t('coilUsage')" class="w-10/12 lg:w-3/4"
        :stats="bobinePieStat" v-if="bobinePieStat.length > 0" />

    <HorizontalBarStats class="w-11/12 lg:w-5/6"
        :titre="t('productsByPrinter')" :stats="produitParImprimanteStat" />
  </div>


  <div class="flex flex-col lg:flex-row mx-8 md:mx-16 mt-8 mb-16 items-center">
    <DateVerticalBarsStat :titre="t('orderFor3Years')" :stats="stats.value.dateCommandeParMois"
      class="w-11/12 lg:w-5/6"/>

    <DateVerticalBarsStat :titre="t('productFor3Years')" :stats="stats.value.nbrProduitParMois"
      class="w-11/12 lg:w-5/6"/>
  </div>
</template>

<script setup>

import {computed, defineProps, reactive} from "vue";
import {UtilisateurStores} from "@/stores/UtilisateurStores";
import converter from "../utils/Converter";
import {ArcElement, Chart, Legend, PieController, registerables, Tooltip} from "chart.js";
import {t} from "@/utils/Traduction";
import {enumeration} from "../../Enumeration";
import {router} from "@/routeur";
import {NotificationStores} from "@/stores/NotificationStores";
import TitreDeux from "@/components/atoms/TitreDeux.vue";
import SimpleText from "@/components/atoms/SimpleText.vue";
import IdCardStats from "@/components/organismes/IdCardStats.vue";
import PieStats from "@/components/molecules/PieStats.vue";
import HorizontalBarStats from "@/components/molecules/HorizontalBarStats.vue";
import DateVerticalBarsStat from "@/components/molecules/DateVerticalBarsStat.vue";

const utilisateurStore = UtilisateurStores();
const notificationStore = NotificationStores();

if(utilisateurStore.role !== enumeration.role.ADMIN) {
  utilisateurStore.logout(false);
  notificationStore.addNotification(t("youNeedToBeAdminToAccessThisPage"), 'error');
  router.push({ path: "/"});
}

Chart.register(PieController, ArcElement, Tooltip, Legend, ...registerables);

const props = defineProps({
  uuid: {
    type: String,
    required: true,
  },
});


let stats = reactive({value: {
    utilisateur: {email: ''},
    nbPiecesTotal: 0,
    nbCommandes: 0,
    nbCommandesAnnuler: 0,
    avgPiecesParCommande: 0,
    nbPoidsTotal: 0,
    avgPoids: 0,
    nbTempsTotal: 0,
    avgTemps: 0,
    prixProductionTotal: 0,
    prixTotal: 0,
    couleurs: [],
    resteAPayer: 0,
    nbProduitParImprimante: [],
  }});

const bobinePieStat = computed(() => {
  return stats.value.couleurs.map((couleur) => {
    return {value: couleur.total, color: couleur.code, text: couleur.libelle};
  });
});

const produitParImprimanteStat = computed(() => {
  return stats.value.nbProduitParImprimante.map((imprimante) => {
    return {value: imprimante.total, text: imprimante.libelle};
  });
});

utilisateurStore.getStatsByUser(props.uuid).then((response) => {
  response.nbPoidsTotal = response.nbPoidsTotal / 1000;
  response.couleurs = response.couleurs.map((couleur) => {
    couleur.total = couleur.total / 1000;
    return couleur;
  });

  stats.value = response;
});

</script>

<style scoped>

</style>