<template>
  <BaseIdCard :numberPerRow="3" :border="isExpired() ? 'border-b-[#ff0000]' : 'border-b-[#00ff00]'">

    <BaseKeyValue :cles="t('uuid')">
      <SimpleText :content="props.information.uuid" />
    </BaseKeyValue>

    <BaseKeyValue :cles="t('message')">
      <SimpleText :content="props.information.message" v-show="!isEditing" />
      <TextAreaInput v-model="editMessage" :placeholder="t('isEditing')"
         v-show="isEditing" showLabel/>
    </BaseKeyValue>

    <BaseKeyValue :cles="t('endDate')" >
      <SimpleText v-show="!isEditing"
          :content='endDateValue' />
      <InputField placeholder="" v-model="editDate" type="datetime-local" v-show="isEditing"/>
    </BaseKeyValue>

    <div class="mt-4 flex flex-row gap-2 justify-end">
      <GreenCheckIcon v-show="isEditing" @click="onEdit" />
      <OrangeEditIcon v-show="!isEditing" @click="() => {isEditing = true;}" />
      <RedTrashIcon @click="onDelete" />
    </div>
  </BaseIdCard>
</template>

<script setup>

import {t} from "@/utils/Traduction";
import {computed, defineProps, ref} from 'vue';
import Converter from "@/utils/Converter";
import {InformationStore} from "@/stores/InformationStore";
import {NotificationStores} from "@/stores/NotificationStores";
import GreenCheckIcon from "@/components/atoms/GreenCheckIcon.vue";
import OrangeEditIcon from "@/components/atoms/OrangeEditIcon.vue";
import RedTrashIcon from "@/components/atoms/RedTrashIcon.vue";
import BaseIdCard from "@/components/molecules/BaseIdCard.vue";
import BaseKeyValue from "@/components/molecules/BaseKeyValue.vue";
import SimpleText from "@/components/atoms/SimpleText.vue";
import TextAreaInput from "@/components/molecules/TextAreaInput.vue";
import InputField from "@/components/molecules/InputField.vue";

const props = defineProps({
  information: Object,
  showModalDelete: Function
});

const informationStore = InformationStore();
const notificationStores = NotificationStores();

const isExpired = () => {
  return new Date(props.information.endDate) < new Date() && props.information.endDate !== null;
}

const isEditing = ref(false);
const editDate = ref(Converter.convertIsoToYYMMDDHHmmDate(props.information.endDate));
const editMessage = ref(props.information.message);
const endDateValue = computed(() => props.information.endDate ? Converter.convertIsoToYYMMDDHHmmDate(props.information.endDate).replace("T", " ") : t("any"))


const onDelete = () => {
  props.showModalDelete(props.information.uuid);
}

const onEdit = () => {
  if(editMessage.value === "") {
    notificationStores.addNotification(t("messageCannotBeEmpty"), 'error');
    return;
  }

  if(editDate.value === "") {
    editDate.value = null;
  }

  informationStore.editInformation(editMessage.value, editDate.value, props.information.uuid);
  isEditing.value = false;
}

</script>

<style scoped>

</style>