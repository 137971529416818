<template>
  <div class="mt-8 md:mt-16 flex items-center justify-center md:mx-0 mx-4 mb-8">
    <div class="w-full md:w-2/3 lg:max-w-md  space-y-8 p-8 bg-white rounded shadow-xl flex flex-col gap-2">
      <SimpleText :content='t("noNeedFillFormFull") + "<br/>" + t("ifChangeMailOnlyMailWillChange")' dangerouslySetInnerHTML  />

      <Titre1 :content="t('modifyMyInformation')" />

      <EditUserForm />

    </div>
  </div>
</template>

<script setup>

import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {NotificationStores} from "@/stores/NotificationStores";
import {router} from "@/routeur";
import {t} from "@/utils/Traduction";
import EditUserForm from "@/components/organismes/EditUserForm.vue";
import SimpleText from "@/components/atoms/SimpleText.vue";
import Titre1 from "@/components/atoms/TitreUn.vue";

const utilisateurStore = UtilisateurStores();
const notificationStores = NotificationStores();

if(!utilisateurStore.isConnected) {
  notificationStores.addNotification(t("youNeedToBeConnectedToAccessThisPage"), 'error');
  router.push({ path: "/" });
}

</script>

<style scoped>

</style>