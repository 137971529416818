<template>
  <BaseDeleteModal :text='t("cautionDeleteRepair")' :isVisible="showDeleteModal" @closeModal="closeModal" />

  <BaseIdCard innerClass="!flex-row justify-between">
    <div class="flex flex-col">
      <BaseKeyValue :cles="t('label')">
        <SimpleText :content="reparation.libelle"  v-show="!isEditMode" />
        <InputField :placeholder="t('label')" v-model="editLibelle" v-show="isEditMode"/>
      </BaseKeyValue>

      <BaseKeyValue :cles="t('price')">
        <SimpleText :content="reparation.prix + '€'" v-show="!isEditMode" />
        <InputField :placeholder="t('price')" v-model="editPrice" v-show="isEditMode"
            type="number" :min="1" />
      </BaseKeyValue>

      <SimpleText :content="converter.convertIsoToYYMMDDHHmmDate(reparation.date)" color="text-gray-500" />
    </div>

    <div class="flex flex-col justify-center gap-2">
      <BlueCheckIcon v-show="isEditMode" @click="editReparation" />
      <OrangeEditIcon v-show="!isEditMode" @click="isEditMode = !isEditMode" />
      <RedTrashIcon @click="showDeleteModal = true" />
    </div>
  </BaseIdCard>
</template>

<script setup>
import converter from "@/utils/Converter";
import {defineProps, reactive, ref} from 'vue';
import {t} from "@/utils/Traduction";
import {NotificationStores} from "@/stores/NotificationStores";
import xhr from "@/utils/xhr";
import {jsonValidator} from "@/utils/JsonValidator";
import SimpleText from "@/components/atoms/SimpleText.vue";
import BlueCheckIcon from "@/components/atoms/BlueCheckIcon.vue";
import OrangeEditIcon from "@/components/atoms/OrangeEditIcon.vue";
import RedTrashIcon from "@/components/atoms/RedTrashIcon.vue";
import BaseDeleteModal from "@/components/molecules/BaseDeleteModal.vue";
import BaseIdCard from "@/components/molecules/BaseIdCard.vue";
import BaseKeyValue from "@/components/molecules/BaseKeyValue.vue";
import InputField from "@/components/molecules/InputField.vue";

const props = defineProps({
  reparationProps: Object,
  deleteReparation: Function
});

const reparation = reactive(props.reparationProps);

const notificationStore = NotificationStores();

const isEditMode = ref(false);
const showDeleteModal = ref(false);

const editLibelle = ref(reparation.libelle);
const editPrice = ref(reparation.prix);

const editReparation = () => {
  if(editLibelle.value === "" || editPrice.value < 0) {
    notificationStore.addNotification(t("needFillAllFields"), "error");
    return;
  }

  xhr.addRequestToQueue("PUT", "/reparations/" + reparation.uuid,  { libelle: editLibelle.value, prix: editPrice.value }, true, true, false,
      (response) => {
        if(response.code !== 200) {
          if (response.code === 0 || !jsonValidator.validate(response.response)) {
            notificationStore.addNotification(
                t("errorDuringEditRepair"),
                "error");
          } else {
            notificationStore.addNotification(
                JSON.parse(response.response).message,
                "error");
          }
        } else {
          notificationStore.addNotification(t("repairEdited"), "success");

          reparation.libelle = editLibelle.value;
          reparation.prix = editPrice.value;

          isEditMode.value = false;
        }
      });
}

const closeModal = (hasDelete) => {
  showDeleteModal.value = false;

  if(hasDelete) {
    xhr.addRequestToQueue("DELETE", "/reparations/" + reparation.uuid,  undefined, true, true, false,
        (response) => {
          if(response.code !== 204) {
            if (response.code === 0 || !jsonValidator.validate(response.response)) {
              notificationStore.addNotification(
                  t("errorDuringDeleteRepair"),
                  "error");
            } else {
              notificationStore.addNotification(
                  JSON.parse(response.response).message,
                  "error");
            }
          } else {
            notificationStore.addNotification(t("repairDeleted"), "success");

            props.deleteReparation(reparation.uuid);
          }
        });
  }
}

</script>

<style scoped>

</style>