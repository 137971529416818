<template>
  <div class="mt-8 md:mt-16 flex items-center justify-center md:mx-0 mx-4 mb-8">
    <div class="w-full md:w-2/3 lg:max-w-md space-y-8 p-8 bg-white rounded shadow-xl flex flex-col items-center">
      <TitreUn :content='t("inscriptionForm")' />

      <CreateUserForm />
    </div>
  </div>
</template>

<script setup>

import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {router} from "@/routeur";
import {NotificationStores} from "@/stores/NotificationStores";
import {enumeration} from '../../Enumeration';
import {t} from "@/utils/Traduction";
import TitreUn from "@/components/atoms/TitreUn.vue";
import CreateUserForm from "@/components/organismes/CreateUserForm.vue";

const utilisateurStore = UtilisateurStores();
const notificationStore = NotificationStores();

if(utilisateurStore.role !== enumeration.role.ADMIN) {

  utilisateurStore.logout(false);
  notificationStore.addNotification(t('youNeedToBeAdminToAccessThisPage'), 'error');
  router.push({ path: "/"});
}

utilisateurStore.loadRoles();

</script>

<style scoped>

</style>