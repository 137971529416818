<template>
  <InformationsBar v-show="information.show === true" v-for="information in informationStore.activeInformations" :key="information.uuid" :information="information" />
  <div class="flex flex-col items-center">
    <div class="flex flex-col lg:flex-row justify-between w-full px-8 pt-4 gap-2 lg:gap-0">
      <div class="flex flex-col lg:flex-row lg:items-center gap-2 lg:gap-4">
        <TitreDeux :content='t("filter")' fontSemiBold />

        <DropDownField :placeholder='t("user")' :values="userForFilter"
          @change="fetchCommandes(true)" v-model="userSelectedFilter"/>

        <DropDownField :placeholder="t('status')" :values="etatForFilter"
          @change="fetchCommandes(true)" v-model="etatSelectedFilter"/>
      </div>

      <BlueButton v-show="!isMultiOrderInvoceMode" :text='t("createInvoiceMultiOrder")' @click="startNewMultiOrderInvoice" />
      <BlueButton v-show="isMultiOrderInvoceMode" :text='selectedMultiOrderInvoce.length + " " + t("forInvoice")' @click="downloadNewMultiOrderInvoice" />
    </div>

    <TitreUn v-show="commandeStores.commandes.length === 0 && !commandeStores.commandesLoading" class="mt-16" :content='t("noOrderCorresponding")' />

    <div class="flex flex-wrap justify-center mx-8 mt-8 w-full">
      <IdCardCommande :toogleOrderToMultiOrderInvoice="toogleOrderToMultiOrderInvoice" :isMultiOrderInvoceMode="isMultiOrderInvoceMode" v-for="commande of commandeStores.commandes" :commande="commande" :key="commande.uuid"/>
    </div>

    <BlueButton v-show="commandeStores.hasMore && commandeStores.commandes.length > 0" class="w-1/2 my-8"
          @click="fetchCommandes(false)" :text='t("showMore")' />
  </div>

</template>

<script setup>

import IdCardCommande from "@/components/organismes/IdCardCommande.vue";
import {CommandeStores} from "@/stores/CommandeStores";
import {router} from "@/routeur";
import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {NotificationStores} from "@/stores/NotificationStores";
import {enumeration} from '../../Enumeration';
import {t} from "@/utils/Traduction";
import {InformationStore} from "@/stores/InformationStore";
import InformationsBar from "@/components/molecules/InformationsBar.vue";
import {computed, ref} from "vue";
import {EtatStore} from "@/stores/EtatStore";
import DropDownField from "@/components/molecules/DropDownField.vue";
import TitreDeux from "@/components/atoms/TitreDeux.vue";
import BlueButton from "@/components/molecules/BlueButton.vue";
import TitreUn from "@/components/atoms/TitreUn.vue";

const utilisateurStore = UtilisateurStores();
const notificationStore = NotificationStores();
const informationStore = InformationStore();
const etatStore = EtatStore();

if(utilisateurStore.role !== enumeration.role.ADMIN) {

  utilisateurStore.logout(false);
  notificationStore.addNotification(t("youNeedToBeAdminToAccessThisPage"), 'error');
  router.push({ path: "/"});
}

const commandeStores = CommandeStores();

informationStore.loadActiveInformations();
utilisateurStore.loadClients();
etatStore.loadEtatsCommandes();

const userSelectedFilter = ref("null");
const etatSelectedFilter = ref("null");
const offset = ref(0);
const isMultiOrderInvoceMode = ref(false);
const selectedMultiOrderInvoce = ref([]);

const userForFilter = computed(() => {
  const client = utilisateurStore.clients.map(u => ({id: u.uuid, display: u.email}));
  return [{id: "null", display: t("allClient")}, ...client];
});

const etatForFilter = computed(() => {
  const etat = etatStore.etats.map(e => ({id: e.uuid, display: e.libelle}));
  return [{id: "null", display: t("allStatus")}, ...etat];
})

const startNewMultiOrderInvoice = () => {
  isMultiOrderInvoceMode.value = true;
  selectedMultiOrderInvoce.value = [];
}

const toogleOrderToMultiOrderInvoice = (uuid) => {
  if(selectedMultiOrderInvoce.value.includes(uuid)) {
    selectedMultiOrderInvoce.value = selectedMultiOrderInvoce.value.filter((value) => value !== uuid);
  } else {
    selectedMultiOrderInvoce.value.push(uuid);
  }
}

const downloadNewMultiOrderInvoice = async () => {

  if(selectedMultiOrderInvoce.value.length === 0) {
    notificationStore.addNotification(t("noOrderSelected"), 'error');
    return;
  }

  await commandeStores.downloadMultiOrderInvoice(selectedMultiOrderInvoce.value);
  isMultiOrderInvoceMode.value = false;
  selectedMultiOrderInvoce.value = [];
}

const fetchCommandes = (reset) => {

  if(reset === true) {
    offset.value = 0;
    commandeStores.commandes = [];
    commandeStores.hasMore = true;
  }

  commandeStores.getCommandeFromOffsetAdmin(offset.value, userSelectedFilter.value, etatSelectedFilter.value);
  offset.value += 20;
}

if(commandeStores.commandes.length === 0) {
  fetchCommandes(true)
}


</script>

<style scoped>

</style>