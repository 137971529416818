<template>
  <div class="mt-8 md:mt-16 md:mx-0 mx-4 flex items-center justify-center">
    <div class="w-full md:w-2/3 lg:max-w-md  space-y-8 p-8 bg-white rounded shadow-xl flex flex-col items-center">
      <TitreUn :content="t('connection')" />

      <LoginForm class="w-full" />
    </div>
  </div>
</template>

<script setup>

import {router} from "@/routeur";
import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {enumeration} from "../../Enumeration";
import {t} from "@/utils/Traduction";
import TitreUn from "@/components/atoms/TitreUn.vue";
import LoginForm from "@/components/organismes/LoginForm.vue";

const utilisateurStore = UtilisateurStores();

if(utilisateurStore.isConnected) {
  if(utilisateurStore.role === enumeration.role.ADMIN) {
    router.push({path: "/admin/commandes"});
  } else {
    router.push({path: "/commandes"});
  }
}

</script>

<style scoped>

</style>