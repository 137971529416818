<template>
  <div class="flex flex-col w-full lg:w-auto lg:flex-row items-center gap-4">
    <InputField :placeholder="t('weightInGrammes')" v-model="nouveauPoidsRestant" type="number" :min="1"/>

    <DropDownField :placeholder='t("selectAColor")'
       :values="couleurStore.couleurs.map(c => ({id: c.uuid, display: c.libelle}))"
       v-model="nouveauUuidCouleur" />

    <DropDownField :placeholder='t("selectABrand")'
       :values="marqueStore.marques.map(c => ({id: c.uuid, display: c.nom}))"
       v-model="nouveauUuidMarque" />

    <DropDownField :placeholder='t("selectAFilament")'
       :values="typeFilamentStore.typeFilaments.map(c => ({id: c.uuid, display: c.libelle}))"
       v-model="nouveauUuidTypeFilament" />

    <BlueButton :text="t('addAction')" @click="createBobine" class="h-1/2 w-full lg:w-auto"/>
  </div>
</template>

<script setup>

import {t} from "@/utils/Traduction";
import BlueButton from "@/components/molecules/BlueButton.vue";
import DropDownField from "@/components/molecules/DropDownField.vue";
import InputField from "@/components/molecules/InputField.vue";
import {CouleurStore} from "@/stores/CouleurStore";
import {MarqueStore} from "@/stores/MarqueStore";
import {TypeFilamentStore} from "@/stores/TypeFilamentStore";
import {ref} from "vue";
import {NotificationStores} from "@/stores/NotificationStores";
import {BobineStore} from "@/stores/BobineStore";

const couleurStore = CouleurStore();
const marqueStore = MarqueStore();
const typeFilamentStore = TypeFilamentStore();
const notificationStore = NotificationStores();
const bobineStore = BobineStore();

const nouveauPoidsRestant = ref(1000);
const nouveauUuidCouleur = ref(-1);
const nouveauUuidMarque = ref(-1);
const nouveauUuidTypeFilament = ref(-1);

const createBobine = () => {
  if(nouveauPoidsRestant.value <= 0) {
    notificationStore.addNotification(t("remeningWeightInfZero"), 'error');
    return;
  }

  if(nouveauUuidCouleur.value === -1) {
    notificationStore.addNotification(t("youNeedToSelectAColor"), 'error');
    return;
  }

  if(nouveauUuidMarque.value === -1) {
    notificationStore.addNotification(t("youNeedToSelectABrand"), 'error');
    return;
  }

  if(nouveauUuidTypeFilament.value === -1) {
    notificationStore.addNotification(t("youNeedToSelectAFilament"), 'error');
    return;
  }

  bobineStore.createBobine({
    poidsRestant: nouveauPoidsRestant.value,
    uuidCouleur: nouveauUuidCouleur.value,
    uuidMarque: nouveauUuidMarque.value,
    uuidTypeFilament: nouveauUuidTypeFilament.value
  });

  nouveauPoidsRestant.value = 0;
  nouveauUuidCouleur.value = -1;
  nouveauUuidMarque.value = -1;
  nouveauUuidTypeFilament.value = -1;
}
</script>

<style scoped>

</style>