<template>
  <div class="bg-black/10 h-screen absolute left-0 top-0 w-full flex justify-center items-center">
    <div class="w-[90%] lg:w-1/3 bg-white rounded-lg shadow-xl p-8 flex flex-col gap-4">
      <TitreDeux :content="t('erreurDuringFillFields')"/>
      <SimpleText :content="texte" />

      <div class="flex flex-row items-center justify-end">
        <RedClickableText :text="t('continue')" @click="props.closeModal()"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineProps} from "vue";
import {t} from "@/utils/Traduction";
import TitreDeux from "@/components/atoms/TitreDeux.vue";
import SimpleText from "@/components/atoms/SimpleText.vue";
import RedClickableText from "@/components/molecules/RedClickableText.vue";


const props = defineProps({
  closeModal: {
    type: Function,
    required: true
  },
  texte: {
    type: String,
    required: true
  }
});

</script>

<style scoped>

</style>
