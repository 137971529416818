<template>
  <h2 class="text-lg" :class="{'font-bold': !fontSemiBold, 'font-semibold': fontSemiBold}">{{props.content}}</h2>
</template>

<script setup>

import {defineProps} from 'vue';

const props = defineProps({
  content: {
    type: String,
    required: true
  },
  fontSemiBold: {
    type: Boolean,
    default: false
  }
})

</script>

<style scoped>

</style>