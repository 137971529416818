<template>
  <BaseKeyValue :cles='t("remainingWeight")'>
    <InputField :placeholder='t("newCoilWeight")' v-model="newPoids" type="number" />
  </BaseKeyValue>

  <BaseKeyValue :cles="t('color')">
    <DropDownField :placeholder="t('selectAColor')" v-model="newCouleurUuid"
         :values="couleurStore.couleurs.map(c => ({id: c.uuid, display: c.libelle}))" />
  </BaseKeyValue>

  <BaseKeyValue :cles="t('brand')">
    <DropDownField :placeholder="t('selectABrand')" v-model="newMarqueUuid"
         :values="marqueStore.marques.map(c => ({id: c.uuid, display: c.nom}))" />
  </BaseKeyValue>

  <BaseKeyValue :cles="t('filaments')">
    <DropDownField :placeholder="t('selectAFilament')" v-model="newTypeFilamentUuid"
         :values="typeFilamentStore.typeFilaments.map(c => ({id: c.uuid, display: c.libelle}))" />
  </BaseKeyValue>

</template>

<script setup>

import {t} from "@/utils/Traduction";
import {defineExpose, defineProps, ref} from "vue";
import {CouleurStore} from "@/stores/CouleurStore";
import {MarqueStore} from "@/stores/MarqueStore";
import {TypeFilamentStore} from "@/stores/TypeFilamentStore";
import {BobineStore} from "@/stores/BobineStore";
import BaseKeyValue from "@/components/molecules/BaseKeyValue.vue";
import DropDownField from "@/components/molecules/DropDownField.vue";
import InputField from "@/components/molecules/InputField.vue";

const props = defineProps({
  bobine: {
    type: Object,
    required: true
  }
});

const bobineStore = BobineStore();
const couleurStore = CouleurStore();
const marqueStore = MarqueStore();
const typeFilamentStore = TypeFilamentStore();

const newPoids = ref(props.bobine.poidsRestant);
const newCouleurUuid = ref(props.bobine.couleur.uuid);
const newMarqueUuid = ref(props.bobine.marque.uuid);
const newTypeFilamentUuid = ref(props.bobine.typeFilament.uuid);

const saveChanges = () => {
  bobineStore.modifierBobine(props.bobine.uuid, newPoids.value, newCouleurUuid.value,
      newMarqueUuid.value, newTypeFilamentUuid.value);
}

defineExpose({
  saveChanges
})

</script>

<style scoped>

</style>