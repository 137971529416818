<template>
  <hr class="ml-[3%] w-[94%] mt-4 h-[1px] border border-blue-600"/>
</template>

<script setup>

</script>

<style scoped>

</style>